import { ReactNode, useEffect, createContext, useState, useContext } from "react";
import { protectedResources } from "../authConfig";
import { useApiGetWithAuth } from "../hooks/useEntityApi";
import { User } from "../models/User";
import { MsalContext } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export const UserContext = createContext({} as User);

export const RequireUser = (props: { children: ReactNode }) => {
	const { execute, result, isCallingApi, error } = useApiGetWithAuth<User>(protectedResources.api.endpoint + "api/me");
	const [loading, setLoading] = useState(true);

	const msalContext = useContext(MsalContext);
	const { inProgress: msalInProgress } = msalContext;

	useEffect(() => {
		if (msalInProgress != InteractionStatus.None) {
			console.log(`*** msal in progress (${msalInProgress}) - not calling api`);
			return;
		}
		try {
			execute();
		} finally {
			setLoading(false);
		}
	}, [execute, msalInProgress]);

	if (isCallingApi || loading) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	if (error) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const e = error as any;
		if (e.statusCode == 404) {
			// handle 404 as special case
			return (
				<>
					<div>User details not found - you must be invited to use this site</div>
				</>
			);
		}
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (!result) {
		return <>Error: User not found but no error returned!</>;
	}

	return (
		<>
			<UserContext.Provider value={result}>{props.children}</UserContext.Provider>
		</>
	);
};

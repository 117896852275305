export const Priority = (props: { value: number }) => {
	const value = Math.max(1, Math.min(3, props.value));
	const starCount = 4 - value;

	return (
		<>
			<span className="stars">
				<img className="star" src={`/wishstar-purple${starCount}.png`} />
			</span>
		</>
	);
};

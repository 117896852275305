import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useBoolean } from "../hooks/useBoolean";
import {
	Button,
	Dialog,
	DialogSurface,
	DialogBody,
	DialogTitle,
	DialogContent,
	DialogActions,
	TableColumnDefinition,
	createTableColumn,
	useTableFeatures,
	useTableSort,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "@fluentui/react-components";
import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { Delete32Regular, Edit32Regular } from "@fluentui/react-icons";

import { useDialogStyles } from "../hooks/useDialogStyles";
import { WishlistUser } from "../models/WishlistUser";

export const UserList = (params: { wishlistId: string }) => {
	const { wishlistId } = params;
	const [activityMessage, setActivityMessage] = useState<string | null>(null);

	const [isDeleteConfirmationDialogOpen, { setTrue: showDeleteConfirmationDialog, setFalse: hideDeleteConfirmationDialog }] =
		useBoolean(false);
	const [userToDelete, setuserToDelete] = useState<WishlistUser | null>(null);

	const usersApi = useEntityApiSimple<WishlistUser>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/users`,
	});

	const { executeList, entityList: items } = usersApi; // extract this as dependency for useEffect below (since this is what is )

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");
			await executeList();
			setActivityMessage(null);
		}
		execute();
	}, [executeList]);

	const confirmDelete = useCallback(
		function (user: WishlistUser) {
			setuserToDelete(user);
			showDeleteConfirmationDialog();
		},
		[setuserToDelete, showDeleteConfirmationDialog]
	);
	const deleteUser = useCallback(
		async function () {
			if (!userToDelete) {
				console.log("!!!deleteUser: no userToDelete");
				setuserToDelete(null);
				hideDeleteConfirmationDialog();
				return;
			}
			setActivityMessage("deleting...");
			await usersApi.executeDeleteItem(userToDelete.userId.toString());
			await executeList(`wishlistId=${wishlistId}`);
			setActivityMessage(null);
			setuserToDelete(null);
			hideDeleteConfirmationDialog();
		},
		[userToDelete]
	);

	const columns: TableColumnDefinition<WishlistUser>[] = [
		createTableColumn<WishlistUser>({
			columnId: "userId",
			compare: (a, b) => {
				return a.userId.localeCompare(b.userId);
			},
		}),
		createTableColumn<WishlistUser>({
			columnId: "email",
			compare: (a, b) => {
				return a.email.localeCompare(b.email);
			},
		}),
		createTableColumn<WishlistUser>({
			columnId: "displayName",
			compare: (a, b) => {
				return a.displayName.localeCompare(b.displayName);
			},
		}),
		createTableColumn<WishlistUser>({
			columnId: "accessType",
			compare: (a, b) => {
				return a.accessType.localeCompare(b.accessType);
			},
		}),
	];

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: items ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "email", sortDirection: "descending" },
			}),
		]
	);
	const headerSortProps = (columnId: keyof WishlistUser) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const rows = sort(getRows());

	const styles = useDialogStyles();

	return (
		<>
			{activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
			<div className="user-list">
				{rows?.length === 0 ? (
					<>No users for this wishlist.</>
				) : (
					<>
						<Table sortable>
							<TableHeader>
								<TableRow>
									<TableHeaderCell {...headerSortProps("userId")}></TableHeaderCell>
									<TableHeaderCell {...headerSortProps("email")}>Email</TableHeaderCell>
									<TableHeaderCell {...headerSortProps("displayName")}>Display Name</TableHeaderCell>
									<TableHeaderCell {...headerSortProps("accessType")}>Access Type</TableHeaderCell>
									<TableHeaderCell></TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{rows?.map((row) => (
									<TableRow key={row.item.userId}>
										<TableCell>
											<Link to={`/lists/${wishlistId}/users/${row.item.userId}`}>{row.item.userId}</Link>
										</TableCell>
										<TableCell>{row.item.email}</TableCell>
										<TableCell>{row.item.displayName}</TableCell>
										<TableCell>{row.item.accessType}</TableCell>
										<TableCell>
											<Link to={`/lists/${wishlistId}/users/${row.item.userId}`}>
												<Button icon={<Edit32Regular />}>Edit</Button>
											</Link>
											<Button icon={<Delete32Regular />} onClick={() => confirmDelete(row.item)}>
												Delete
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<Dialog open={isDeleteConfirmationDialogOpen}>
							<DialogSurface>
								<DialogBody>
									<DialogTitle>Delete user?</DialogTitle>
									<DialogContent>Are you sure you want to delete the selected user ({userToDelete?.userId})?</DialogContent>
									<DialogActions>
										<Button appearance="secondary" onClick={hideDeleteConfirmationDialog}>
											Cancel
										</Button>
										<Button appearance="primary" onClick={deleteUser}>
											Delete
										</Button>
									</DialogActions>
								</DialogBody>
							</DialogSurface>
						</Dialog>
					</>
				)}
			</div>
		</>
	);
};

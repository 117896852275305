import { BaseSyntheticEvent, useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { useApiPostWithAuth, useEntityApi, useEntityApiSimple } from "../hooks/useEntityApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Wishlist } from "../models/Wishlist";
import { useBoolean } from "../hooks/useBoolean";
import { useDialogStyles } from "../hooks/useDialogStyles";
import {
	Field,
	Input,
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent,
	Button,
	Dropdown,
	Option,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";

interface InviteCreateModel {
	wishlistId: number;
	recipientEmail: string;
	accessType: "user" | "admin" | "owner";
}
interface Invite extends InviteCreateModel {
	id: number;
	createdDate: string;
	accepted: boolean;
}
type InviteCreateResponse =
	| {
			status: "user-added";
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			status: "invite-created";
			invite: Invite;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  };

function convertInviteToEditModel(entity: Invite): InviteCreateModel {
	return {
		recipientEmail: entity.recipientEmail,
		wishlistId: entity.wishlistId,
		accessType: entity.accessType,
	};
}
export const WishlistInviteAdd = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const navigate = useNavigate();
	const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
		useBoolean(false);
	const [activityMessage, setActivityMessage] = useState<string | null>(null);
	const [listName, setListName] = useState<string | null>(null);
	const [formReadonly, { setTrue: setFormReadonly }] = useBoolean(false);

	const wishlistsApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const invitesApi = useEntityApi<Invite, InviteCreateModel>({
		collectionEndpoint: protectedResources.api.endpoint + `api/invites`,
		convertToEdit: convertInviteToEditModel,
		initialEntityValue: {
			recipientEmail: "",
			wishlistId: parseInt(wishlistId),
			accepted: false,
			createdDate: "",
			accessType: "user",
			id: -1,
		},
	});

	const { execute: executeInviteCreate, error: inviteCreateError } = useApiPostWithAuth<InviteCreateModel, InviteCreateResponse>(
		protectedResources.api.endpoint + `api/invites`
	);

	const [updateCount, setUpdateCount] = useState(0);
	const [showSavedBanner, setShowSavedBanner] = useState(false);
	const [savedBannerMessage, setSavedBannerMessage] = useState<string>("");

	const { executeGet: getWishlistExecute } = wishlistsApi; // extract this as dependency for useEffect below (since this is what is )

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");
			const { entity: wishlist } = await getWishlistExecute();
			setListName(wishlist?.name ?? null);
		}
		execute();
	}, [getWishlistExecute, updateCount]);

	const saveEntity = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		console.log("!!!saveEntity");
		console.log({
			entity: invitesApi.entity,
			validatedEntity: invitesApi.validatedEntity,
		});
		setActivityMessage("saving...");
		if (!invitesApi.entity) {
			throw new Error("Wishlist not loaded");
		}
		const updatedEntity = await executeInviteCreate(convertInviteToEditModel(invitesApi.entity));
		if (updatedEntity) {
			setUpdateCount(updateCount + 1);
			if (updatedEntity.status === "user-added") {
				setSavedBannerMessage("User added to wishlist");
			} else {
				setSavedBannerMessage("Invite created - user will receive an email");
			}
			setShowSavedBanner(true);
			setFormReadonly();
			setTimeout(() => {
				setShowSavedBanner(false);
				navigate(`/lists/${wishlistId}/edit`);
			}, 2000);
		}
	};

	const validatedInvite = invitesApi.validatedEntity;
	const getErrorForField = useCallback(
		(property: keyof InviteCreateModel) => {
			if (validatedInvite && !validatedInvite[property].isValid) {
				return validatedInvite[property].errorMessages?.join("; ");
			}
			return undefined;
		},
		[validatedInvite]
	);

	const styles = useDialogStyles();

	const error = wishlistsApi.error ?? invitesApi.error ?? inviteCreateError;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	return (
		<>
			<h1>
				<Link to="/lists">Wish lists</Link> / <Link to={`/lists/${wishlistId}`}>{listName ?? "<loading...>"}</Link> / Add invite
			</h1>
			{showSavedBanner && <div className={styles.savedBanner}>{savedBannerMessage}</div>}
			{invitesApi.isCallingApi && <div className={styles.savingBanner}>{activityMessage}</div>}
			{invitesApi.error && <div className={styles.errorBanner}>Error: {invitesApi.error.message}</div>}

			<div className="wishlist-edit-dialog">
				{validatedInvite && (
					<>
						{validatedInvite.entityValidationErrors?.length > 0 && <div>Errors: {validatedInvite.entityValidationErrors.join("; ")}</div>}
						<Field label="Email" orientation="vertical" required={true} validationMessage={getErrorForField("recipientEmail")}>
							<Input
								defaultValue={validatedInvite.recipientEmail.value}
								onChange={(_, data) => invitesApi.updateEntityProperties({ recipientEmail: data.value })}
								disabled={formReadonly}
							/>
						</Field>
						<Field label="Access Type" orientation="vertical" required={true} validationMessage={getErrorForField("accessType")}>
							<Dropdown
								defaultValue="User"
								onOptionSelect={(_, data) => {
									invitesApi.updateEntityProperties({ accessType: data.optionValue as "user" | "admin" | "owner" });
								}}
							>
								<Option value="user" text="User">
									User
								</Option>
								<Option value="admin" text="Admin">
									Admin
								</Option>
								<Option value="owner" text="Owner">
									Owner
								</Option>
							</Dropdown>
						</Field>

						<div className={styles.actions}>
							<Button appearance="primary" onClick={saveEntity} disabled={formReadonly}>
								Invite
							</Button>
							<Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog} disabled={formReadonly}>
								Cancel
							</Button>
							<Dialog open={isDiscardConfirmationDialogOpen}>
								<DialogSurface>
									<DialogBody>
										<DialogTitle>Cancel invite?</DialogTitle>
										<DialogContent>Are you sure you want to cancel creating an invite?</DialogContent>
										<DialogActions>
											<Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
												Return
											</Button>
											<Button appearance="primary" onClick={() => navigate(`/lists/${wishlistId}`)}>
												Cancel invite
											</Button>
										</DialogActions>
									</DialogBody>
								</DialogSurface>
							</Dialog>
						</div>
					</>
				)}
			</div>
		</>
	);
};

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Field } from "@fluentui/react-components";

import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { Edit32Regular, Cart24Regular, ArrowBounce16Regular } from "@fluentui/react-icons";
import { Priority } from "../components/Priority";
import { WishlistItem } from "../models/WishlistItem";
import { Link } from "react-router-dom";

import * as MarkdownIt from "markdown-it";
import { Wishlist } from "../models/Wishlist";
import { UserContext } from "../components/RequireUser";

const md = MarkdownIt.default({ linkify: true });

export const WishlistItemShow = () => {
	const { wishlistId, wishlistItemId } = useParams() as { wishlistId: string; wishlistItemId: string };
	const [loading, setLoading] = useState(true);
	const user = useContext(UserContext);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}`,
	});

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeGet: executeGetItem, isCallingApi: isCallingItemsApi, error: errorItems, entity: item } = wishlistItemsApi;

	const navigate = useNavigate();

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItem().then((item) => {
				if (!item) {
					navigate(`/lists/${wishlistId}`);
				}
			});
		}
		load();
		setLoading(false); // once load is called the isCallingApiXXX variables tack the loading :-)
	}, [executeGetList, executeGetItem]);

	const error = errorList || errorItems;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingListApi || isCallingItemsApi || loading) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	const adminOrOwner = wishlistApi.entity?.userAccessType === "owner" || wishlistApi.entity?.userAccessType === "admin";
	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name}
				{adminOrOwner ? (
					<Button icon={<Edit32Regular />} onClick={() => navigate(`/lists/${wishlistId}/items/${wishlistItemId}/edit`)}></Button>
				) : null}
			</h1>
			{item ? (
				<>
					<div className="wishlist-item-show-dialog">
						<Field label="Item" hint="" orientation="vertical">
							{item?.name}
						</Field>
						<Field label="Priority" hint="" orientation="vertical">
							<Priority value={item?.priority} />
						</Field>
						<Field label="Price" hint="" orientation="vertical">
							&pound;{item?.price}
						</Field>
						<Field label="Notes" hint="" orientation="vertical">
							<div dangerouslySetInnerHTML={{ __html: md.render(item?.notes ?? "") }}></div>
						</Field>
					</div>
					{item?.bought?.buyerId?.toLowerCase() == user.userId?.toLowerCase() ? (
						<Button icon={<ArrowBounce16Regular />} onClick={() => navigate(`/lists/${wishlistId}/items/${item.id}/return`)}>
							Return this...
						</Button>
					) : (
						<Button icon={<Cart24Regular />} onClick={() => navigate(`/lists/${wishlistId}/items/${item.id}/buy`)}>
							Get this...
						</Button>
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
};

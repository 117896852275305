import "./App.css";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { PageLayout } from "./components/PageLayout";
import { WishlistList } from "./pages/WishlistList";
import { WishlistEdit } from "./pages/WishlistEdit";
import { WishlistShow } from "./pages/WishlistShow";
import { Home } from "./pages/Home";
import { RequireUser } from "./components/RequireUser";
import { ReactNode } from "react";
import { WishlistItemShow } from "./pages/WishlistItemShow";
import { WishlistItemBuy } from "./pages/WishlistItemBuy";
import { WishlistItemReturn } from "./pages/WishlistItemReturn";
import { WishlistItemAdd } from "./pages/WishlistItemAdd";
import { WishlistItemEdit } from "./pages/WishlistItemEdit";
import { WishlistShowBought } from "./pages/WishlistShowBought";
import { WishlistShowArchived } from "./pages/WishlistShowArchived";
import { WishlistInviteAdd } from "./pages/WishlistInviteAdd";
import { WishlistInviteAccept } from "./pages/WishlistInviteAccept";
import { WishlistUserEdit } from "./pages/WishlistUserEdit";
import { AdminListList } from "./pages/admin/AdminListList";
import { AdminListUserList } from "./pages/admin/AdminListUserList";

const RequireAuthAndUser = (props: { children: ReactNode }) => {
	return (
		<>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<RequireUser>{props.children}</RequireUser>
			</MsalAuthenticationTemplate>
		</>
	);
};

const Pages = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/invite/:inviteCode" element={<WishlistInviteAccept />} />
			{/* Wrap /lists* routes in an element that checks users are signed in and registered */}
			<Route
				element={
					<RequireAuthAndUser>
						<Outlet />
					</RequireAuthAndUser>
				}
			>
				<Route path="/admin/lists" element={<AdminListList />} />
				<Route path="/admin/list-users" element={<AdminListUserList />} />

				<Route path="/lists" element={<WishlistList />} />
				<Route path="/lists/:wishlistId" element={<WishlistShow />} />
				<Route path="/lists/:wishlistId/edit" element={<WishlistEdit />} />
				<Route path="/lists/:wishlistId/bought" element={<WishlistShowBought />} />
				<Route path="/lists/:wishlistId/archived" element={<WishlistShowArchived />} />
				<Route path="/lists/:wishlistId/invites/add" element={<WishlistInviteAdd />} />
				<Route path="/lists/:wishlistId/items" element={<Navigate to={window.location.pathname.replace("/items", "")} />} />
				<Route path="/lists/:wishlistId/items/add" element={<WishlistItemAdd />} />
				<Route path="/lists/:wishlistId/items/:wishlistItemId" element={<WishlistItemShow />} />
				<Route path="/lists/:wishlistId/items/:wishlistItemId/edit" element={<WishlistItemEdit />} />
				<Route path="/lists/:wishlistId/items/:wishlistItemId/buy" element={<WishlistItemBuy />} />
				<Route path="/lists/:wishlistId/items/:wishlistItemId/return" element={<WishlistItemReturn />} />
				<Route path="/lists/:wishlistId/users/:wishlistUserId" element={<WishlistUserEdit />} />
			</Route>
		</Routes>
	);
};

const App = ({ instance }: { instance: PublicClientApplication }) => {
	return (
		<MsalProvider instance={instance}>
			<PageLayout>
				<Pages />
			</PageLayout>
		</MsalProvider>
	);
};

export default App;

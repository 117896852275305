import { BaseSyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "../hooks/useBoolean";
import {
	Field,
	Input,
	Textarea,
	Button,
	Dialog,
	DialogSurface,
	DialogBody,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@fluentui/react-components";
import { protectedResources } from "../authConfig";
import { useDialogStyles } from "../hooks/useDialogStyles";
import { useEntityApi } from "../hooks/useEntityApi";
import { Wishlist, WishlistEditModel } from "../models/Wishlist";

function convertWishlistToEditModel(entity: Wishlist): WishlistEditModel {
	return {
		name: entity.name,
		boughtEmailSubject: entity.boughtEmailSubject,
		boughtEmailTemplate: entity.boughtEmailTemplate,
		returnEmailSubject: entity.returnEmailSubject,
		returnEmailTemplate: entity.returnEmailTemplate,
	};
}

export const WishlistEditForm = (params: { wishlistId: string }) => {
	const { wishlistId } = params;
	const navigate = useNavigate();
	const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
		useBoolean(false);
	const [activityMessage, setActivityMessage] = useState<string | null>(null);

	const wishlistsApi = useEntityApi<Wishlist, WishlistEditModel>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
		convertToEdit: convertWishlistToEditModel,
	});
	const [updateCount, setUpdateCount] = useState(0);
	const [showSavedBanner, setShowSavedBanner] = useState(false);

	const { executeGet: getWishlistExecute } = wishlistsApi; // extract this as dependency for useEffect below (since this is what is )

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");
			await getWishlistExecute();
		}
		execute();
	}, [getWishlistExecute, updateCount]);
	const saveEntity = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		console.log("!!!saveEntity");
		console.log({
			entity: wishlistsApi.entity,
			validatedEntity: wishlistsApi.validatedEntity,
		});
		setActivityMessage("saving...");
		const updatedEntity = await wishlistsApi.executeUpdate();
		if (updatedEntity) {
			setUpdateCount(updateCount + 1);
			setShowSavedBanner(true);
			setTimeout(() => setShowSavedBanner(false), 1000);
		}
	};

	const validatedWishlist = wishlistsApi.validatedEntity;
	const getErrorForField = useCallback(
		(property: keyof WishlistEditModel) => {
			if (validatedWishlist && !validatedWishlist[property].isValid) {
				return validatedWishlist[property].errorMessages?.join("; ");
			}
			return undefined;
		},
		[validatedWishlist]
	);

	const styles = useDialogStyles();

	return (
		<>
			{showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
			{wishlistsApi.isCallingApi && <div className={styles.savingBanner}>{activityMessage}</div>}
			{wishlistsApi.error && <div className={styles.errorBanner}>Error: {wishlistsApi.error.message}</div>}

			<div className="wishlist-edit-dialog">
				{validatedWishlist && (
					<>
						{validatedWishlist.entityValidationErrors?.length > 0 && (
							<div>Errors: {validatedWishlist.entityValidationErrors.join("; ")}</div>
						)}
						<Field label="Name" orientation="vertical" required={true} validationMessage={getErrorForField("name")}>
							<Input
								defaultValue={validatedWishlist.name.value}
								onChange={(_, data) => wishlistsApi.updateEntityProperties({ name: data.value })}
							/>
						</Field>
						<Field
							label="Bought Email Subject"
							orientation="vertical"
							required={true}
							validationMessage={getErrorForField("boughtEmailSubject")}
						>
							<Input
								defaultValue={validatedWishlist.boughtEmailSubject.value}
								onChange={(_, data) =>
									wishlistsApi.updateEntityProperties({
										boughtEmailSubject: data.value,
									})
								}
							/>
						</Field>
						<Field
							label="Bought Email Template"
							orientation="vertical"
							required={true}
							validationMessage={getErrorForField("boughtEmailTemplate")}
						>
							<Textarea
								rows={5}
								defaultValue={validatedWishlist.boughtEmailTemplate.value ?? ""}
								onChange={(_, data) =>
									wishlistsApi.updateEntityProperties({
										boughtEmailTemplate: data.value,
									})
								}
							/>
						</Field>
						<Field
							label="Return Email Subject"
							orientation="vertical"
							required={true}
							validationMessage={getErrorForField("returnEmailSubject")}
						>
							<Input
								defaultValue={validatedWishlist.returnEmailSubject.value}
								onChange={(_, data) =>
									wishlistsApi.updateEntityProperties({
										returnEmailSubject: data.value,
									})
								}
							/>
						</Field>
						<Field
							label="Return Email Template"
							orientation="vertical"
							required={true}
							validationMessage={getErrorForField("returnEmailTemplate")}
						>
							<Textarea
								rows={5}
								defaultValue={validatedWishlist.returnEmailTemplate.value ?? ""}
								onChange={(_, data) =>
									wishlistsApi.updateEntityProperties({
										returnEmailTemplate: data.value,
									})
								}
							/>
						</Field>
						<div className={styles.actions}>
							<Button appearance="primary" onClick={saveEntity}>
								Save
							</Button>
							<Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
								Cancel
							</Button>
							<Dialog open={isDiscardConfirmationDialogOpen}>
								<DialogSurface>
									<DialogBody>
										<DialogTitle>Discard changes?</DialogTitle>
										<DialogContent>Are you sure you want to discard any changes?</DialogContent>
										<DialogActions>
											<Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
												Cancel
											</Button>
											<Button appearance="primary" onClick={() => navigate(`/lists/${wishlistId}`)}>
												Discard
											</Button>
										</DialogActions>
									</DialogBody>
								</DialogSurface>
							</Dialog>
						</div>
					</>
				)}
			</div>
		</>
	);
};

import { BaseSyntheticEvent, useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { useEntityApi, useEntityApiSimple } from "../hooks/useEntityApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Wishlist } from "../models/Wishlist";
import { useBoolean } from "../hooks/useBoolean";
import { useDialogStyles } from "../hooks/useDialogStyles";
import {
	Field,
	Input,
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent,
	Button,
	Textarea,
	Dropdown,
	Option,
	Checkbox,
} from "@fluentui/react-components";
import { WishlistItem, WishlistItemEditModel } from "../models/WishlistItem";
import { Priority } from "../components/Priority";
import { Link } from "react-router-dom";

export const WishlistItemAdd = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const navigate = useNavigate();
	const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
		useBoolean(false);
	const [activityMessage, setActivityMessage] = useState<string | null>(null);
	const [listName, setListName] = useState<string | null>(null);

	const wishlistsApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApi<WishlistItem, WishlistItemEditModel>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
		convertToEdit: (entity) => {
			return {
				name: entity.name,
				price: entity.price,
				notes: entity.notes,
				priority: entity.priority,
				hidden: entity.hidden,
				archived: entity.archived,
			};
		},
		initialEntityValue: {
			name: "",
			price: 0,
			notes: "",
			priority: 1,
			// full withlist fields
			id: -1,
			archived: false,
			bought: undefined,
			hidden: false,
			listedDate: "",
		},
	});

	const { executeGet: getWishlistExecute } = wishlistsApi; // extract this as dependency for useEffect below (since this is what is )

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");
			const { entity: wishlist } = await getWishlistExecute();
			setListName(wishlist?.name ?? null);
		}
		execute();
	}, [getWishlistExecute]);

	const saveEntity = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		console.log("!!!saveEntity");
		console.log({
			entity: wishlistsApi.entity,
			validatedEntity: wishlistsApi.validatedEntity,
		});
		setActivityMessage("saving...");
		const { entity: updatedEntity } = await wishlistItemsApi.executeInsert();
		if (updatedEntity) {
			navigate(`/lists/${wishlistId}/items/${updatedEntity.id}`);
		}
	};

	const validatedWishlistItem = wishlistItemsApi.validatedEntity;
	console.log({ validatedWishlistItem });
	const getErrorForField = useCallback(
		(property: keyof WishlistItemEditModel) => {
			if (validatedWishlistItem && !validatedWishlistItem[property].isValid) {
				return validatedWishlistItem[property].errorMessages?.join("; ");
			}
			return undefined;
		},
		[validatedWishlistItem]
	);

	const styles = useDialogStyles();

	const error = wishlistsApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	const priorityOptions: Record<string, string> = {
		"1": "Must have",
		"2": "Love to have",
		"3": "Nice to have",
	};

	return (
		<>
			<h1>
				<Link to="/lists">Wish lists</Link> / <Link to={`/lists/${wishlistId}`}>{listName ?? "<loading...>"}</Link> / Edit
			</h1>
			{wishlistsApi.isCallingApi && <div className={styles.savingBanner}>{activityMessage}</div>}
			{wishlistsApi.error && <div className={styles.errorBanner}>Error: {wishlistsApi.error.message}</div>}

			<div className={styles.dialogEdit}>
				{validatedWishlistItem && (
					<>
						{validatedWishlistItem.entityValidationErrors?.length > 0 && (
							<div>Errors: {validatedWishlistItem.entityValidationErrors.join("; ")}</div>
						)}
						<Field
							label="Name"
							hint="Name of the item"
							orientation="horizontal"
							required={true}
							validationMessage={getErrorForField("name")}
						>
							<Input
								defaultValue={validatedWishlistItem.name.value}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ name: data.value })}
							/>
						</Field>
						<Field
							label="Price"
							hint="Price of the item"
							orientation="horizontal"
							required={true}
							validationMessage={getErrorForField("price")}
						>
							<Input
								defaultValue={validatedWishlistItem.price.value?.toString()}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ price: data.value ? parseFloat(data.value) : undefined })}
							/>
						</Field>
						<Field
							label="Priority"
							hint="Item priority"
							orientation="horizontal"
							required={true}
							validationMessage={getErrorForField("priority")}
						>
							<Dropdown
								defaultValue={priorityOptions[validatedWishlistItem.priority.value?.toString()]}
								onOptionSelect={(_, data) => {
									console.log(data);
									wishlistItemsApi.updateEntityProperties({ priority: parseInt(data.optionValue ?? "2") });
								}}
							>
								{[1, 2, 3].map((i) => (
									<Option key={i.toString()} text={priorityOptions[i.toString()]} value={i.toString()}>
										<Priority value={i} /> {priorityOptions[i.toString()]}
									</Option>
								))}
							</Dropdown>
						</Field>
						<Field
							label="Notes"
							hint="Notes about the item (description, links, ...)"
							orientation="horizontal"
							required={true}
							validationMessage={getErrorForField("notes")}
						>
							<Textarea
								rows={5}
								defaultValue={validatedWishlistItem.notes.value ?? ""}
								onChange={(_, data) =>
									wishlistItemsApi.updateEntityProperties({
										notes: data.value,
									})
								}
							/>
						</Field>
						<Field
							label="Hidden"
							hint="Should this item be hidden from list users?"
							orientation="horizontal"
							required={true}
							validationMessage={getErrorForField("hidden")}
						>
							<Checkbox
								checked={validatedWishlistItem.hidden.value}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ hidden: data.checked == true })}
							/>
						</Field>
						<div className={styles.actions}>
							<Button appearance="primary" onClick={saveEntity}>
								Save
							</Button>
							<Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
								Cancel
							</Button>
							<Dialog open={isDiscardConfirmationDialogOpen}>
								<DialogSurface>
									<DialogBody>
										<DialogTitle>Discard changes?</DialogTitle>
										<DialogContent>Are you sure you want to discard any changes?</DialogContent>
										<DialogActions>
											<Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
												Cancel
											</Button>
											<Button appearance="primary" onClick={() => navigate(`/lists/${wishlistId}`)}>
												Discard
											</Button>
										</DialogActions>
									</DialogBody>
								</DialogSurface>
							</Dialog>
						</div>
					</>
				)}
			</div>
		</>
	);
};

import { useEffect } from "react";

import { protectedResources } from "../../authConfig";
import { useEntityApiSimple } from "../../hooks/useEntityApi";
import {
	Table,
	TableBody,
	TableCell,
	TableColumnDefinition,
	TableColumnId,
	TableHeader,
	TableHeaderCell,
	TableRow,
	createTableColumn,
	makeStyles,
	shorthands,
	tokens,
	useTableFeatures,
	useTableSort,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";

interface WishlistUserSummary {
	userId: string;
	email: string;
	displayName: string;
	accessType: "user" | "admin" | "owner";
	wishlist: {
		id: number;
		name: string;
	};
}

const useStyles = makeStyles({
	card: {
		width: "360px",
		maxWidth: "100%",
		height: "fit-content",
		marginBottom: "1em",
	},

	horizontalCardImage: {
		width: "64px",
		height: "64px",
	},

	headerImage: {
		...shorthands.borderRadius("4px"),
		maxWidth: "44px",
		maxHeight: "44px",
	},

	caption: {
		color: tokens.colorNeutralForeground3,
	},
});
export const AdminListUserList = () => {
	const wishlistUsersApi = useEntityApiSimple<WishlistUserSummary>({
		collectionEndpoint: protectedResources.api.endpoint + "api/admin/list-users",
	});

	const { executeList, isCallingApi, error, entityList: wishlistUsers } = wishlistUsersApi;

	useEffect(() => {
		executeList();
	}, [executeList]);

	const styles = useStyles();

	const columns: TableColumnDefinition<WishlistUserSummary>[] = [
		createTableColumn<WishlistUserSummary>({
			columnId: "listName",
			compare: (a, b) => {
				return a.wishlist.name.localeCompare(b.wishlist.name);
			},
		}),
		createTableColumn<WishlistUserSummary>({
			columnId: "email",
			compare: (a, b) => {
				return a.email.localeCompare(b.email);
			},
		}),
		createTableColumn<WishlistUserSummary>({
			columnId: "displayName",
			compare: (a, b) => {
				return a.displayName.localeCompare(b.displayName);
			},
		}),
		createTableColumn<WishlistUserSummary>({
			columnId: "accessType",
			compare: (a, b) => {
				return a.accessType.localeCompare(b.accessType);
			},
		}),
	];

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: wishlistUsers ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "userId", sortDirection: "ascending" },
			}),
		]
	);
	const headerSortProps = (columnId: TableColumnId) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const rows = sort(getRows());

	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingApi) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	if (wishlistUsers?.length === 0) {
		return <>No users.</>;
	}

	return (
		<>
			<h1>
				<Link to="/">Admin</Link> / List Users
			</h1>
			<div>
				<Table sortable>
					<TableHeader>
						<TableRow>
							<TableHeaderCell {...headerSortProps("listName")}>List</TableHeaderCell>
							<TableHeaderCell {...headerSortProps("email")}>Email</TableHeaderCell>
							<TableHeaderCell {...headerSortProps("displayName")}>Display Name</TableHeaderCell>
							<TableHeaderCell {...headerSortProps("accessType")}>Access Type</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={`${row.item.userId}|${row.item.wishlist.id}`}>
								<TableCell>{row.item.wishlist.name}</TableCell>
								<TableCell>{row.item.email}</TableCell>
								<TableCell>{row.item.displayName}</TableCell>
								<TableCell>{row.item.accessType}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</>
	);
};

import { useState, useCallback, useEffect } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../index";
import { protectedResources } from "../authConfig";
import { ApiValidationError, ValidatedEntity, getValidatedEntity } from "../models/ValidatedEntity";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getToken(request: any) {
	const currentAccounts = msalInstance.getAllAccounts();

	let accountId = "";
	// let username = "";

	if (currentAccounts.length === 0) {
		return null;
	} else if (currentAccounts.length > 1) {
		// Add your account choosing logic here
		console.warn("Multiple accounts detected.");
		return null;
	} else if (currentAccounts.length === 1) {
		accountId = currentAccounts[0].homeAccountId;
		// username = currentAccounts[0].username;
	}

	/**
	 * See here for more information on account retrieval:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
	 */

	request.account = msalInstance.getAccountByHomeId(accountId);
	return msalInstance.acquireTokenSilent(request).catch((error) => {
		console.warn(error);
		console.warn("silent token acquisition fails. acquiring token using redirect");
		if (error instanceof InteractionRequiredAuthError) {
			// fallback to interaction when silent call fails
			// return msalInstance.acquireTokenPopup(request)
			return msalInstance
				.acquireTokenRedirect(request)
				.then((response) => {
					console.log(response);
					return response;
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			console.warn(error);
		}
	});
}

async function getApiAuthHeaderValue() {
	const tokenRequest = {
		scopes: protectedResources.api.scopes.general,
	};

	const response = await getToken(tokenRequest);
	if (response) {
		return `Bearer ${response.accessToken}`;
	}
	// trigger login if we don't get credentials (e.g. if they've expired)
	const loginRequest = {
		scopes: protectedResources.api.scopes.general,
	};
	await msalInstance.loginRedirect(loginRequest);
	return null;
}
function useApiHeaderAuth() {
	return [useCallback(getApiAuthHeaderValue, [])] as const;
}
async function callApiWWithAuth<T>(endpoint: string, method: string, data?: T) {
	const bearerToken = await getApiAuthHeaderValue();
	if (!bearerToken) {
		throw new Error("token not set");
	}
	const headers = new Headers();
	headers.append("Authorization", bearerToken);
	if (data) headers.append("Content-Type", "application/json");

	const options = {
		method: method,
		headers: headers,
		body: data ? JSON.stringify(data) : null,
	};

	const response = await fetch(endpoint, options);
	return response;
}

export class HttpError extends Error {
	constructor(public statusCode: number, message: string) {
		super(message);
	}
}

async function getErrorFromresponse(response: Response) {
	const body = await response.text();
	try {
		const jsonBody = JSON.parse(body);
		if (jsonBody.error || jsonBody.message) {
			return new HttpError(response.status, jsonBody.error ?? jsonBody.message);
		}
	} catch (e) {
		// ignore
	}
	if (body.length > 0) {
		return new HttpError(response.status, body);
	} else {
		return new HttpError(response.status, response.statusText);
	}
}

export function useApiGetWithAuth<TResponse>(endpoint: string) {
	const [isCallingApi, setIsCallingApi] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [result, setResult] = useState<TResponse | null>(null);

	const execute = useCallback(async (endpointOverride?: string) => {
		setIsCallingApi(true);
		try {
			const response = await callApiWWithAuth(endpointOverride ?? endpoint, "GET");
			if (response.ok) {
				const result = (await response.json()) as TResponse;
				setResult(result);
				return result;
			} else {
				throw new HttpError(response.status, response.statusText);
			}
		} catch (e) {
			setError(e as Error);
		} finally {
			setIsCallingApi(false);
		}
	}, []);

	return { execute, result, isCallingApi, error };
}
export function useApiPostWithAuth<TRequest, TResponse>(endpoint?: string) {
	return useApiWithBody<TRequest, TResponse>("POST", endpoint);
}
export function useApiPatchWithAuth<TRequest, TResponse>(endpoint?: string) {
	return useApiWithBody<TRequest, TResponse>("PATCH", endpoint);
}
export function useApiDeleteWithAuth<TRequest, TResponse>(endpoint?: string) {
	return useApiWithBody<TRequest, TResponse>("DELETE", endpoint);
}
function useApiWithBody<TRequest, TResponse>(method: string, endpoint?: string) {
	const [isCallingApi, setIsCallingApi] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [result, setResult] = useState<TResponse | null>(null);

	const execute = useCallback(async (data: TRequest, endpointOverride?: string) => {
		setIsCallingApi(true);
		try {
			const url = endpointOverride ?? endpoint;
			if (!url) {
				throw new Error("One of endpoint or endpointOverride must be set");
			}
			const response = await callApiWWithAuth(url, method, data);
			if (response.ok) {
				const responseBody = await response.text();
				if (responseBody.length > 0) {
					const result = JSON.parse(responseBody) as TResponse;
					setResult(result);
					return result;
				} else {
					setResult(null);
					return null;
				}
			} else {
				const httpErr = await getErrorFromresponse(response);
				throw httpErr;
			}
		} catch (e) {
			setError(e as Error);
		} finally {
			setIsCallingApi(false);
		}
	}, []);

	return { execute, result, isCallingApi, error };
}

interface ApiValidationErrorResponse {
	errors: ApiValidationError[];
}

// export function omitId<TEntity>(entity: TEntity): Omit<TEntity, "id"> {
// 	const { id, ...rest } = entity as any; // eslint-disable-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
// 	return rest as Omit<TEntity, "id">;
// }

export interface EntityApiSimpleOptions<TEntityFull> {
	collectionEndpoint: string;
	individualEndpoint?: string;
	initialEntityValue?: TEntityFull;
}

export interface EntityApiOptions<TEntityFull, TEntityEdit> extends EntityApiSimpleOptions<TEntityFull> {
	convertToEdit: (entity: TEntityFull) => TEntityEdit;
}

function NullConverter<T>(entity: T) {
	return entity;
}
export function useEntityApiSimple<TEntityFull>(options: EntityApiSimpleOptions<TEntityFull>) {
	return useEntityApi<TEntityFull, TEntityFull>({
		...options,
		convertToEdit: NullConverter,
	});
}
export function useEntityApi<TEntityFull extends TEntityEdit, TEntityEdit>(options: EntityApiOptions<TEntityFull, TEntityEdit>) {
	const { collectionEndpoint, individualEndpoint } = options;
	const [isCallingApi, setIsCallingApi] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [entity, setEntitySingle] = useState<TEntityFull | null>(null);
	const [entityList, setEntityList] = useState<TEntityFull[] | null>(null);
	const [validatedEntity, setValidatedEntity] = useState<ValidatedEntity<TEntityEdit> | null>(null);
	const [apiValidationErrors, setApiValidationErrors] = useState<ApiValidationError[]>([]);
	const [getApiAuthHeaderValue] = useApiHeaderAuth();

	const executeGet = useCallback(
		async function () {
			if (!individualEndpoint) {
				throw new Error("individualEndpoint not set");
			}
			const convertToEdit = options.convertToEdit;
			const bearerToken = await getApiAuthHeaderValue();
			if (bearerToken) {
				try {
					const headers = new Headers();
					headers.append("Authorization", bearerToken);

					const options = {
						method: "GET",
						headers: headers,
					};

					setIsCallingApi(true);
					const response = await fetch(individualEndpoint, options);
					if (!response.ok) {
						// Some other failure
						throw new Error(response.statusText);
					}
					const entityResponseFull = (await response.json()) as TEntityFull;
					const entityResponse = convertToEdit(entityResponseFull);
					setEntitySingle(entityResponseFull);
					setValidatedEntity(getValidatedEntity(entityResponse, []));
					setIsCallingApi(false);
					return { entity: entityResponseFull, error: null };
				} catch (e) {
					console.error("Error from fetch?", e);
					setError(e as Error);
					setIsCallingApi(false);
					return { entity: null, error: e as Error };
				}
			} else {
				console.error("token not set");
				const e = new Error("token not set");
				setError(e);
				return { entity: null, error: e };
			}
		},
		[getApiAuthHeaderValue, individualEndpoint, options.convertToEdit]
	);

	const executeDelete = useCallback(
		async function () {
			if (!individualEndpoint) {
				throw new Error("individualEndpoint not set");
			}
			const bearerToken = await getApiAuthHeaderValue();
			if (bearerToken) {
				try {
					const headers = new Headers();
					headers.append("Authorization", bearerToken);

					const options = {
						method: "DELETE",
						headers: headers,
					};

					setIsCallingApi(true);
					const response = await fetch(individualEndpoint, options);
					if (!response.ok) {
						// Some other failure
						throw new Error(response.statusText);
					}
					setIsCallingApi(false);
					return { error: null };
				} catch (e) {
					console.error("Error from fetch?", e);
					setError(e as Error);
					setIsCallingApi(false);
					return { error: e as Error };
				}
			} else {
				console.error("token not set");
				const e = new Error("token not set");
				setError(e);
				return { error: e };
			}
		},
		[getApiAuthHeaderValue, individualEndpoint]
	);

	const executeList = useCallback(
		async function (queryString?: string): Promise<{ list: TEntityFull[] | null; error: Error | null }> {
			const bearerToken = await getApiAuthHeaderValue();
			if (bearerToken) {
				try {
					const headers = new Headers();
					headers.append("Authorization", bearerToken);

					const options = {
						method: "GET",
						headers: headers,
					};
					const url = queryString ? `${collectionEndpoint}?${queryString}` : collectionEndpoint;

					setIsCallingApi(true);
					const response = await fetch(url, options);
					if (!response.ok) {
						// Some other failure
						throw new Error(response.statusText);
					}
					const entityResponseList = (await response.json()) as TEntityFull[];
					setEntityList(entityResponseList);
					setIsCallingApi(false);
					return { list: entityResponseList, error: null };
				} catch (e) {
					console.error("Error from fetch?", e);
					setError(e as Error);
					setIsCallingApi(false);
					return { list: null, error: e as Error };
				}
			} else {
				console.error("token not set");
				const e = new Error("token not set");
				setError(e);
				return { list: null, error: e };
			}
		},
		[collectionEndpoint, getApiAuthHeaderValue]
	);

	// Delete an item from the list
	const executeDeleteItem = useCallback(
		async function (id: string) {
			const bearerToken = await getApiAuthHeaderValue();
			if (bearerToken) {
				try {
					const headers = new Headers();
					headers.append("Authorization", bearerToken);

					const options = {
						method: "DELETE",
						headers: headers,
					};
					const url = `${collectionEndpoint}/${id}`;

					setIsCallingApi(true);
					const response = await fetch(url, options);
					if (!response.ok) {
						// Some other failure
						throw new Error(response.statusText);
					}
					setIsCallingApi(false);
					return true;
				} catch (e) {
					console.error("Error from fetch?", e);
					setError(e as Error);
					setIsCallingApi(false);
					return false;
				}
			} else {
				console.error("token not set");
				setError(new Error("token not set"));
				return false;
			}
		},
		[collectionEndpoint, executeList, getApiAuthHeaderValue]
	);

	const executeUpdateBase = useCallback(
		async function (
			method: "GET" | "POST" | "PUT",
			endpoint: string,
			entityOverride?: TEntityFull
		): Promise<{ entity: TEntityFull | null; error: Error | null }> {
			const convertToEdit = options.convertToEdit;
			const bearerToken = await getApiAuthHeaderValue();
			if (bearerToken) {
				try {
					const headers = new Headers();
					headers.append("Authorization", bearerToken);

					const entityToUse = entityOverride ?? entity;
					if (entityToUse) headers.append("Content-Type", "application/json");

					const convertedEntity = entityToUse ? convertToEdit(entityToUse) : null;

					console.log("executeUpdateBase", {
						method,
						endpoint,
						entity,
						convertedEntity,
						convertToEdit,
					});
					const options = {
						method: method,
						headers: headers,
						body: convertedEntity ? JSON.stringify(convertedEntity) : null,
					};

					setIsCallingApi(true);
					const response = await fetch(endpoint, options);
					if (response.status === 422) {
						// validation errors
						const validationResponse = (await response.json()) as ApiValidationErrorResponse;
						setApiValidationErrors(validationResponse.errors);
						setValidatedEntity(getValidatedEntity(entity, validationResponse.errors));
						setIsCallingApi(false);
						return { entity: null, error: new Error("Validation failed") };
					}
					if (!response.ok) {
						// Some other failure
						throw new Error(response.statusText);
					}
					const entityResponseFull = (await response.json()) as TEntityFull;
					const entityResponse = convertToEdit(entityResponseFull);
					setApiValidationErrors([]);
					setValidatedEntity(getValidatedEntity(entityResponse, []));
					setIsCallingApi(false);
					return { entity: entityResponseFull, error: null };
				} catch (e) {
					console.error("Error from fetch?", e);
					setError(e as Error);
					setIsCallingApi(false);
					return { entity: null, error: e as Error };
				}
			} else {
				console.error("token not set");
				const e = new Error("token not set");
				setError(e);
				return { entity: null, error: e };
			}
		},
		[entity, getApiAuthHeaderValue, options.convertToEdit]
	);

	const setEntity = useCallback(
		function setEntityFunc(entity: TEntityFull) {
			setEntitySingle(entity);
			const convertToEdit = options.convertToEdit;
			const updateEntity = convertToEdit(entity);
			setValidatedEntity(getValidatedEntity(updateEntity, apiValidationErrors));
		},
		[apiValidationErrors, options.convertToEdit]
	);

	useEffect(() => {
		if (entity === null && options.initialEntityValue) {
			setEntity(options.initialEntityValue);
			setValidatedEntity(getValidatedEntity(options.convertToEdit(options.initialEntityValue), []));
		}
	});

	function updateEntityProperties(updates: Partial<TEntityFull> | undefined = undefined) {
		if (!entity) {
			throw new Error("Cannot update entity properties when entity is null");
		}
		setEntity({ ...entity, ...updates });
	}

	const executeUpdate = useCallback(
		(entityOverride?: TEntityFull) => {
			if (!individualEndpoint) {
				throw new Error("individualEndpoint must be set to execute update");
			}
			return executeUpdateBase("PUT", individualEndpoint, entityOverride);
		},
		[entity, executeUpdateBase, individualEndpoint]
	);
	return {
		isCallingApi,
		error,
		validatedEntity,
		entity,
		entityList,
		setEntity,
		updateEntityProperties,
		executeGet,
		executeList,
		executeDelete,
		executeDeleteItem,
		executeInsert: useCallback(() => executeUpdateBase("POST", collectionEndpoint), [executeUpdateBase, collectionEndpoint]),
		executeUpdate: individualEndpoint
			? executeUpdate
			: () => {
					throw new Error("Cannot update when individualEndpoint is not set");
			  }, // eslint-disable-line no-mixed-spaces-and-tabs
	};
}

import { useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { useParams } from "react-router-dom";
import { Wishlist } from "../models/Wishlist";
import { Link } from "react-router-dom";
import { WishlistEditForm } from "../components/WishlistEditForm";
import { InviteList } from "../components/InviteList";
import { UserList } from "../components/UserList";

export const WishlistEdit = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const [listName, setListName] = useState<string | null>(null);

	const wishlistsApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	useEffect(() => {
		async function execute() {
			const { entity: wishlist } = await wishlistsApi.executeGet();
			setListName(wishlist?.name ?? null);
		}
		execute();
	}, [wishlistsApi.executeGet]);

	const error = wishlistsApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	return (
		<>
			<h1>
				<Link to="/lists">Wish lists</Link> / <Link to={`/lists/${wishlistId}`}>{listName ?? "<loading...>"}</Link> / Edit
			</h1>
			<WishlistEditForm wishlistId={wishlistId} />
			<h2>Users</h2>
			<div className="wishlist-edit-user-list">
				<UserList wishlistId={wishlistId} />
			</div>
			<h2>Invites</h2>
			<div className="wishlist-edit-invite-new">
				<Link to={`/lists/${wishlistId}/invites/add`}>Invite a user to this list</Link>
			</div>
			<div className="wishlist-edit-invite-list">
				<InviteList wishlistId={wishlistId} />
			</div>
		</>
	);
};

import { BaseSyntheticEvent, useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { useEntityApi, useEntityApiSimple } from "../hooks/useEntityApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Wishlist } from "../models/Wishlist";
import { useBoolean } from "../hooks/useBoolean";
import { useDialogStyles } from "../hooks/useDialogStyles";
import {
	Field,
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent,
	Button,
	Dropdown,
	Option,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";

import { WishlistUser, WishlistUserEditModel } from "../models/WishlistUser";

function convertWishlistUserToEditModel(entity: WishlistUser): WishlistUserEditModel {
	return {
		accessType: entity.accessType,
	};
}

export const WishlistUserEdit = () => {
	const { wishlistId, wishlistUserId } = useParams() as { wishlistId: string; wishlistUserId: string };
	const navigate = useNavigate();
	const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
		useBoolean(false);
	const [activityMessage, setActivityMessage] = useState<string | null>(null);
	const [listName, setListName] = useState<string | null>(null);

	const wishlistsApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistUsersApi = useEntityApi<WishlistUser, WishlistUserEditModel>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/users`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/users/${wishlistUserId}`,
		convertToEdit: convertWishlistUserToEditModel,
	});

	const [updateCount, setUpdateCount] = useState(0);
	const [showSavedBanner, setShowSavedBanner] = useState(false);

	const { executeGet: executeGetList } = wishlistsApi;
	const { executeGet: executeGetItem, entity: user } = wishlistUsersApi;

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");

			// avoid awaiting here to parallelise the calls
			executeGetList().then((result) => {
				const { entity: list } = result;
				if (!list) {
					navigate("/lists");
				}
				setListName(list?.name ?? null);
			});
			executeGetItem().then((user) => {
				if (!user) {
					navigate(`/lists/${wishlistId}`);
				}
			});
		}
		execute();
	}, [executeGetList, executeGetItem]);

	const saveEntity = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		console.log("!!!saveEntity");
		console.log({
			entity: wishlistUsersApi.entity,
			validatedEntity: wishlistUsersApi.validatedEntity,
		});
		setActivityMessage("saving...");
		const updatedEntity = await wishlistUsersApi.executeUpdate();
		if (updatedEntity) {
			setUpdateCount(updateCount + 1);
			setShowSavedBanner(true);
			setTimeout(() => setShowSavedBanner(false), 1000);
		}
	};
	const validatedWishlistUser = wishlistUsersApi.validatedEntity;
	const getErrorForField = useCallback(
		(property: keyof WishlistUserEditModel) => {
			if (validatedWishlistUser && !validatedWishlistUser[property].isValid) {
				return validatedWishlistUser[property].errorMessages?.join("; ");
			}
			return undefined;
		},
		[validatedWishlistUser]
	);

	const styles = useDialogStyles();

	const error = wishlistsApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	const userAccessOptions: Record<string, string> = {
		user: "User",
		admin: "Admin",
		owner: "Owner",
	};

	return (
		<>
			<h1>
				<Link to="/lists">Wish lists</Link> / <Link to={`/lists/${wishlistId}`}>{listName ?? "<loading...>"}</Link> /{" "}
				<Link to={`/lists/${wishlistId}/items/${wishlistUserId}`}>{user?.email ?? "<loading...>"}</Link> / Edit
			</h1>
			{showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
			{wishlistUsersApi.isCallingApi && <div className={styles.savingBanner}>{activityMessage}</div>}
			{wishlistUsersApi.error && <div className={styles.errorBanner}>Error: {wishlistUsersApi.error.message}</div>}

			<div className="wishlist-item-edit-dialog">
				{validatedWishlistUser && (
					<>
						{validatedWishlistUser.entityValidationErrors?.length > 0 && (
							<div>Errors: {validatedWishlistUser.entityValidationErrors.join("; ")}</div>
						)}
						<Field label="Access Type" orientation="vertical" required={true} validationMessage={getErrorForField("accessType")}>
							<Dropdown
								defaultValue={userAccessOptions[validatedWishlistUser.accessType.value?.toString()]}
								onOptionSelect={(_, data) => {
									console.log(data);
									wishlistUsersApi.updateEntityProperties({ accessType: (data.optionValue ?? "user") as "user" | "admin" | "owner" });
								}}
							>
								<Option text={userAccessOptions.user} value="user">
									{userAccessOptions.user}
								</Option>
								<Option text={userAccessOptions.admin} value="admin">
									{userAccessOptions.admin}
								</Option>
								<Option text={userAccessOptions.owner} value="owner">
									{userAccessOptions.owner}
								</Option>
							</Dropdown>
						</Field>
						<div className={styles.actions}>
							<Button appearance="primary" onClick={saveEntity}>
								Save
							</Button>
							<Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
								Cancel
							</Button>
							<Dialog open={isDiscardConfirmationDialogOpen}>
								<DialogSurface>
									<DialogBody>
										<DialogTitle>Discard changes?</DialogTitle>
										<DialogContent>Are you sure you want to discard any changes?</DialogContent>
										<DialogActions>
											<Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
												Cancel
											</Button>
											<Button appearance="primary" onClick={() => navigate(`/lists/${wishlistId}/edit`)}>
												Discard
											</Button>
										</DialogActions>
									</DialogBody>
								</DialogSurface>
							</Dialog>
						</div>
					</>
				)}
			</div>
		</>
	);
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

(async function () {
	await msalInstance.initialize();

	// // Default to using the first account if no account is active on page load
	// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// 	const activeAccount = msalInstance.getActiveAccount();
	// 	if (!activeAccount) {
	// 		// Account selection logic is app dependent. Adjust as needed for different use cases.
	// 		// let's use the first account if there is one
	// 		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
	// 	}
	// }

	// Optional - This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents();

	const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<FluentProvider theme={teamsLightTheme}>
					<App instance={msalInstance} />
				</FluentProvider>
			</BrowserRouter>
		</React.StrictMode>
	);
})();

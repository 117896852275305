import { BaseSyntheticEvent, useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { useEntityApi, useEntityApiSimple } from "../hooks/useEntityApi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Wishlist } from "../models/Wishlist";
import { useBoolean } from "../hooks/useBoolean";
import { useDialogStyles } from "../hooks/useDialogStyles";
import {
	Field,
	Input,
	Dialog,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent,
	Button,
	Textarea,
	Checkbox,
	Dropdown,
	Option,
} from "@fluentui/react-components";
import { WishlistItem, WishlistItemEditModel } from "../models/WishlistItem";
import { Priority } from "../components/Priority";
import { Link } from "react-router-dom";

import * as MarkdownIt from "markdown-it";

const md = MarkdownIt.default({ linkify: true });

function convertWishlistitemToEditModel(entity: WishlistItem): WishlistItemEditModel {
	return {
		name: entity.name,
		notes: entity.notes,
		price: entity.price,
		priority: entity.priority,
		hidden: entity.hidden,
		archived: entity.archived,
	};
}

export const WishlistItemEdit = () => {
	const { wishlistId, wishlistItemId } = useParams() as { wishlistId: string; wishlistItemId: string };
	const navigate = useNavigate();
	const [isDiscardConfirmationDialogOpen, { setTrue: showDiscardConfirmationDialog, setFalse: hideDiscardConfirmationDialog }] =
		useBoolean(false);
	const [activityMessage, setActivityMessage] = useState<string | null>(null);
	const [listName, setListName] = useState<string | null>(null);
	const [previewMarkdown, setPreviewMarkdown] = useState<string>("");

	const wishlistsApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApi<WishlistItem, WishlistItemEditModel>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}`,
		convertToEdit: convertWishlistitemToEditModel,
	});

	const [updateCount, setUpdateCount] = useState(0);
	const [showSavedBanner, setShowSavedBanner] = useState(false);

	const { executeGet: executeGetList } = wishlistsApi;
	const { executeGet: executeGetItem, entity: item } = wishlistItemsApi;

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");

			// avoid awaiting here to parallelise the calls
			executeGetList().then((result) => {
				const { entity: list } = result;
				if (!list) {
					navigate("/lists");
				}
				setListName(list?.name ?? null);
			});
			executeGetItem().then((item) => {
				if (!item) {
					navigate(`/lists/${wishlistId}`);
				}
			});
		}
		execute();
	}, [executeGetList, executeGetItem]);

	const saveEntity = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		console.log("!!!saveEntity");
		console.log({
			entity: wishlistItemsApi.entity,
			validatedEntity: wishlistItemsApi.validatedEntity,
		});
		setActivityMessage("saving...");
		const updatedEntity = await wishlistItemsApi.executeUpdate();
		if (updatedEntity) {
			setUpdateCount(updateCount + 1);
			setShowSavedBanner(true);
			setTimeout(() => setShowSavedBanner(false), 1000);
		}
	};
	const validatedWishlistItem = wishlistItemsApi.validatedEntity;
	const getErrorForField = useCallback(
		(property: keyof WishlistItemEditModel) => {
			if (validatedWishlistItem && !validatedWishlistItem[property].isValid) {
				return validatedWishlistItem[property].errorMessages?.join("; ");
			}
			return undefined;
		},
		[validatedWishlistItem]
	);

	useEffect(() => {
		setPreviewMarkdown(validatedWishlistItem?.notes.value ?? "");
	}, [validatedWishlistItem?.notes.value]);

	const styles = useDialogStyles();

	const error = wishlistsApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	const priorityOptions: Record<string, string> = {
		"1": "Must have",
		"2": "Love to have",
		"3": "Nice to have",
	};

	return (
		<>
			<h1>
				<Link to="/lists">Wish lists</Link> / <Link to={`/lists/${wishlistId}`}>{listName ?? "<loading...>"}</Link> /{" "}
				<Link to={`/lists/${wishlistId}/items/${wishlistItemId}`}>{item?.name ?? "<loading...>"}</Link> / Edit
			</h1>
			{showSavedBanner && <div className={styles.savedBanner}>Saved!</div>}
			{wishlistItemsApi.isCallingApi && <div className={styles.savingBanner}>{activityMessage}</div>}
			{wishlistItemsApi.error && <div className={styles.errorBanner}>Error: {wishlistItemsApi.error.message}</div>}

			<div className="wishlist-item-edit-dialog">
				{validatedWishlistItem && (
					<>
						{validatedWishlistItem.entityValidationErrors?.length > 0 && (
							<div>Errors: {validatedWishlistItem.entityValidationErrors.join("; ")}</div>
						)}
						<Field label="Name" orientation="vertical" required={true} validationMessage={getErrorForField("name")}>
							<Input
								defaultValue={validatedWishlistItem.name.value}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ name: data.value })}
							/>
						</Field>
						<Field label="Price" orientation="vertical" required={true} validationMessage={getErrorForField("price")}>
							<Input
								defaultValue={validatedWishlistItem.price.value?.toString()}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ price: data.value ? parseFloat(data.value) : undefined })}
							/>
						</Field>
						<Field label="Priority" orientation="vertical" required={true} validationMessage={getErrorForField("priority")}>
							<Dropdown
								defaultValue={priorityOptions[validatedWishlistItem.priority.value?.toString()]}
								onOptionSelect={(_, data) => {
									console.log(data);
									wishlistItemsApi.updateEntityProperties({ priority: parseInt(data.optionValue ?? "2") });
								}}
							>
								{[1, 2, 3].map((i) => (
									<Option key={i.toString()} text={priorityOptions[i.toString()]} value={i.toString()}>
										<Priority value={i} /> {priorityOptions[i.toString()]}
									</Option>
								))}
							</Dropdown>
						</Field>
						<Field label="Notes" orientation="vertical" required={true} validationMessage={getErrorForField("notes")}>
							<Textarea
								rows={5}
								defaultValue={validatedWishlistItem.notes.value ?? ""}
								onChange={(_, data) =>
									wishlistItemsApi.updateEntityProperties({
										notes: data.value,
									})
								}
							/>
						</Field>
						<div className="label">Notes preview:</div>
						<div className="notes-preview" dangerouslySetInnerHTML={{ __html: md.render(previewMarkdown ?? "") }}></div>
						<Field label="Hidden" orientation="horizontal" required={true} validationMessage={getErrorForField("hidden")}>
							<Checkbox
								checked={validatedWishlistItem.hidden.value}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ hidden: data.checked == true })}
							/>
						</Field>
						<Field label="Archived" orientation="horizontal" required={true} validationMessage={getErrorForField("archived")}>
							<Checkbox
								checked={validatedWishlistItem.archived.value}
								onChange={(_, data) => wishlistItemsApi.updateEntityProperties({ archived: data.checked == true })}
							/>
						</Field>
						<div className={styles.actions}>
							<Button appearance="primary" onClick={saveEntity}>
								Save
							</Button>
							<Button appearance="secondary" className={styles.buttonGap} onClick={showDiscardConfirmationDialog}>
								Cancel
							</Button>
							<Dialog open={isDiscardConfirmationDialogOpen}>
								<DialogSurface>
									<DialogBody>
										<DialogTitle>Discard changes?</DialogTitle>
										<DialogContent>Are you sure you want to discard any changes?</DialogContent>
										<DialogActions>
											<Button appearance="secondary" onClick={hideDiscardConfirmationDialog}>
												Cancel
											</Button>
											<Button appearance="primary" onClick={() => navigate(`/lists/${wishlistId}`)}>
												Discard
											</Button>
										</DialogActions>
									</DialogBody>
								</DialogSurface>
							</Dialog>
						</div>
					</>
				)}
			</div>
		</>
	);
};

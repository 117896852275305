import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button } from "@fluentui/react-components";

import { loginRequest } from "../authConfig";
import { RequireUser, UserContext } from "../components/RequireUser";

import { Link } from "react-router-dom";
const HomeContent = () => {
	const navigate = useNavigate();
	const user = useContext(UserContext);

	useEffect(() => {
		console.log({ user, userType: user?.userType });
		if (user.userType !== "admin") {
			console.log("Site user - navigate to /lists");
			navigate("/lists");
		}
	});

	return (
		<>
			<h2>Main site:</h2>
			<ul>
				<li>
					<Link to="/lists">View lists</Link>
				</li>
			</ul>
			<h2 className="heading">Admin:</h2>
			<ul>
				<li>
					<Link to="/admin/lists">Lists</Link>
				</li>
				<li>
					<Link to="/admin/list-users">List users</Link>
				</li>
			</ul>
			<p>TODO - add list management and any other admin features here 😏</p>
		</>
	);
};

export const Home = () => {
	const { instance } = useMsal();

	return (
		<>
			<AuthenticatedTemplate>
				<RequireUser>
					<HomeContent />
				</RequireUser>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>Welcome to the leeks.net wishlist site.</p>
				<Button appearance="primary" onClick={() => instance.loginRedirect(loginRequest)} className="ml-auto">
					Sign In
				</Button>
			</UnauthenticatedTemplate>
		</>
	);
};

// Wrap a property value with validation information
class ValidatedProperty<T> {
	public value: T;
	public isValid: boolean;
	public errorMessages: string[] | undefined;

	constructor(value: T, errorMessages: string[] | undefined) {
		this.value = value;
		this.isValid = errorMessages === undefined || errorMessages.length === 0;
		this.errorMessages = errorMessages;
	}
}
// Wrap properties of an entity with validation information
type EntityPropertyValidations<T> = {
	[K in keyof T]: ValidatedProperty<T[K]>;
};
// Entity-level validation information
type EntityValidations = {
	isValid: boolean;
	entityValidationErrors: ApiValidationError[];
};
// Combine entity validation with validated properties
export type ValidatedEntity<T> = EntityPropertyValidations<T> & EntityValidations;

export interface ApiValidationError {
	propertyName: string;
	errorMessage: string;
}

export function getValidatedEntity<T>(entity: T, validationErrors: ApiValidationError[]): ValidatedEntity<T> {
	// Group validation errors by property name
	const validationErrorsByPropertyName: Record<string, string[]> = {};
	for (const validationError of validationErrors) {
		const propertyErrorMessages = validationErrorsByPropertyName[validationError.propertyName] || [];
		propertyErrorMessages.push(validationError.errorMessage);
		validationErrorsByPropertyName[validationError.propertyName] = propertyErrorMessages;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const validatedEntity: any = {
		isValid: validationErrors.length === 0,
	};
	for (const key in entity) {
		const propertyErrors = validationErrorsByPropertyName[key];
		delete validationErrorsByPropertyName[key];
		validatedEntity[key] = new ValidatedProperty(entity[key], propertyErrors);
	}

	// Filter remaining errors and attach to entity
	// This catches any errors for properties that don't exist on the entity
	// as well as any with an empty property name (which is used for entity-level errors)
	const remainingErrors = Object.keys(validationErrorsByPropertyName)
		.map((propertyName) => validationErrorsByPropertyName[propertyName])
		.reduce((a, b) => a.concat(b), []);
	validatedEntity.entityValidationErrors = remainingErrors.length > 0 ? remainingErrors : undefined;
	return validatedEntity;
}

import { useEffect } from "react";

import { protectedResources } from "../../authConfig";
import { useEntityApiSimple } from "../../hooks/useEntityApi";
import { Card, CardHeader, CardPreview, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { List16Regular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";

interface WishlistSummary {
	id: number;
	name: string;
}

const useStyles = makeStyles({
	card: {
		width: "360px",
		maxWidth: "100%",
		height: "fit-content",
		marginBottom: "1em",
	},

	horizontalCardImage: {
		width: "64px",
		height: "64px",
	},

	headerImage: {
		...shorthands.borderRadius("4px"),
		maxWidth: "44px",
		maxHeight: "44px",
	},

	caption: {
		color: tokens.colorNeutralForeground3,
	},
});
export const AdminListList = () => {
	const wishlistApi = useEntityApiSimple<WishlistSummary>({
		collectionEndpoint: protectedResources.api.endpoint + "api/admin/lists",
	});

	const { executeList, isCallingApi, error, entityList: wishlists } = wishlistApi;

	useEffect(() => {
		executeList();
	}, [executeList]);

	const styles = useStyles();

	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingApi) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	if (wishlists?.length === 0) {
		return <>You don't seem to have access to any wishlists.</>;
	}

	return (
		<>
			<h1>
				<Link to="/">Admin</Link> / Lists
			</h1>
			<h2 className="heading">Please choose a wishlist to manage:</h2>
			<div className="wishlist-list">
				{wishlists?.map((wishlist) => (
					<Link key={wishlist.id} to={`/admin/lists/${wishlist.id}`} className="wishlist-list-item">
						<Card className={styles.card} orientation="horizontal">
							<CardPreview className={styles.horizontalCardImage}>
								<List16Regular />
							</CardPreview>
							<CardHeader header={wishlist.name}></CardHeader>
						</Card>
					</Link>
				))}
			</div>
		</>
	);
};

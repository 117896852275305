import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	CardFooter,
	CardHeader,
	Field,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableColumnDefinition,
	TableColumnId,
	TableHeader,
	TableHeaderCell,
	TableRow,
	Text,
	createTableColumn,
	makeStyles,
	shorthands,
	tokens,
	useTableFeatures,
	useTableSort,
} from "@fluentui/react-components";

import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { ArrowBounce16Regular } from "@fluentui/react-icons";
import { UserContext } from "../components/RequireUser";
import { Wishlist } from "../models/Wishlist";
import { WishlistItem } from "../models/WishlistItem";

const useStyles = makeStyles({
	card: {
		width: "360px",
		maxWidth: "100%",
		// width: "95%",
		height: "fit-content",
		marginBottom: "1em",
	},
	cardHeader: {
		maxWidth: "100%",
	},
	cardPreview: {
		maxWidth: "100%",
		marginLeft: "1em",
	},
	horizontalCardImage: {
		width: "60px",
		height: "20px",
		marginLeft: "1em",
	},

	headerImage: {
		...shorthands.borderRadius("4px"),
		maxWidth: "44px",
		maxHeight: "44px",
	},

	caption: {
		color: tokens.colorNeutralForeground3,
	},
});

export const WishlistShowBought = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const [loading, setLoading] = useState(true);
	const user = useContext(UserContext);

	const [showAllBought, setShowAllBought] = useState(false);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
	});

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeList: executeGetItems, isCallingApi: isCallingItemsApi, error: errorItems, entityList: items } = wishlistItemsApi;

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItems();
		}
		load();
		setLoading(false); // once load is called the isCallingApiXXX variables tack the loading :-)
	}, [executeGetList, executeGetItems]);

	const navigate = useNavigate();
	const styles = useStyles();

	const columns: TableColumnDefinition<WishlistItem>[] = [
		createTableColumn<WishlistItem>({
			columnId: "name",
			compare: (a, b) => {
				return a.name.localeCompare(b.name);
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "boughtBy",
			compare: (a, b) => {
				return (a.bought?.by ?? "").localeCompare(b.bought?.by ?? "");
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "bought",
			compare: (a, b) => {
				// comparing date property works as the value is in the format YYYY-MM-DDTHH:mm:ss.sssZ :-)
				return (a.bought?.date ?? "").localeCompare(b.bought?.date ?? "");
			},
		}),
	];

	function filterItems(items: WishlistItem[] | null) {
		const userIdLower = user.userId.toLowerCase();
		console.log("filterItems", { items, showAllBought, userIdLower });
		if (showAllBought) {
			return items?.filter((item) => item.bought);
		} else {
			return items?.filter((item) => item.bought?.buyerId?.toLowerCase() == user.userId);
		}
	}

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: filterItems(items) ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "priority", sortDirection: "descending" },
			}),
		]
	);
	const headerSortProps = (columnId: TableColumnId) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const error = errorList || errorItems;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingListApi || isCallingItemsApi || loading) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	const adminOrOwner = wishlistApi.entity?.userAccessType === "owner" || wishlistApi.entity?.userAccessType === "admin";
	const rows = sort(getRows());

	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / Bought items
			</h1>

			{adminOrOwner ? (
				<div>
					<Field label="Show bought items">
						<RadioGroup layout="horizontal" defaultValue="mine" onChange={(_, data) => setShowAllBought(data.value == "all")}>
							<Radio value="mine" label="Just mine" />
							<Radio value="all" label="All bought" />
						</RadioGroup>
					</Field>
				</div>
			) : null}
			<div className="wishlist-show-list">
				{rows?.length === 0 ? (
					<>No bought items in this wishlist.</>
				) : (
					<Table sortable>
						<TableHeader>
							<TableRow>
								<TableHeaderCell {...headerSortProps("name")}>Name</TableHeaderCell>
								<TableHeaderCell {...headerSortProps("bought")}>Bought</TableHeaderCell>
								{showAllBought ? (
									<>
										<TableHeaderCell {...headerSortProps("boughtBy")}>Bought By</TableHeaderCell>
										<TableHeaderCell>Message</TableHeaderCell>
									</>
								) : null}
								<TableHeaderCell></TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{rows?.map((row) => (
								<TableRow key={row.item.id}>
									<TableCell>
										<TableCellLayout appearance="primary">
											<Link to={`/lists/${wishlistId}/items/${row.item.id}`}>{row.item.name}</Link>
										</TableCellLayout>
									</TableCell>
									<TableCell>{row.item.bought?.date ? new Date(Date.parse(row.item.bought.date)).toDateString() : ""}</TableCell>
									{showAllBought ? (
										<>
											<TableCell>{row.item.bought?.by}</TableCell>
											<TableCell>{row.item.bought?.message}</TableCell>
										</>
									) : null}
									<TableCell>
										<Link to={`/lists/${wishlistId}/items/${row.item.id}/return`}>
											<Button icon={<ArrowBounce16Regular />}>Return this...</Button>
										</Link>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</div>
			<div className="wishlist-show-list-small">
				{/* Rendering for narrower devices/windows goes here */}
				{rows?.map((row) => (
					<>
						<Link key={row.item.id} to={`/lists/${wishlistId}/items/${row.item.id}`} className="wishlist-show-item-small">
							<Card className={styles.card} orientation="vertical">
								<CardHeader
									header={
										<Text weight="semibold" size={400}>
											{row.item.name}
										</Text>
									}
									className={styles.cardHeader}
								></CardHeader>
								<CardFooter>
									<Text size={300} className="wishlist-show-item-small-bought-date">
										Bought {row.item.bought?.date ? new Date(Date.parse(row.item.bought.date)).toDateString() : ""}
									</Text>
									<Link to={`/lists/${wishlistId}/items/${row.item.id}/return`} className="wishlist-show-item-small-return">
										<Button icon={<ArrowBounce16Regular />}> Return this...</Button>
									</Link>
								</CardFooter>
							</Card>
						</Link>
					</>
				))}
			</div>
		</>
	);
};

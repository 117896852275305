import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	CardFooter,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableColumnDefinition,
	TableColumnId,
	TableHeader,
	TableHeaderCell,
	TableRow,
	Text,
	createTableColumn,
	makeStyles,
	shorthands,
	tokens,
	useTableFeatures,
	useTableSort,
} from "@fluentui/react-components";
import { Edit32Regular } from "@fluentui/react-icons";

import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { Priority } from "../components/Priority";
import { WishlistItem } from "../models/WishlistItem";
import { Wishlist } from "../models/Wishlist";

// TODO:- move to models
const useStyles = makeStyles({
	card: {
		width: "360px",
		maxWidth: "100%",
		// width: "95%",
		height: "fit-content",
		marginBottom: "1em",
	},
	cardHeader: {
		maxWidth: "100%",
	},
	cardPreview: {
		maxWidth: "100%",
		marginLeft: "1em",
	},
	horizontalCardImage: {
		width: "60px",
		height: "20px",
		marginLeft: "1em",
	},

	headerImage: {
		...shorthands.borderRadius("4px"),
		maxWidth: "44px",
		maxHeight: "44px",
	},

	caption: {
		color: tokens.colorNeutralForeground3,
	},
});

export const WishlistShowArchived = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const [loading, setLoading] = useState(true);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items?type=archived`,
	});

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeList: executeGetItems, isCallingApi: isCallingItemsApi, error: errorItems, entityList: items } = wishlistItemsApi;

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItems();
		}
		load();
		setLoading(false); // once load is called the isCallingApiXXX variables tack the loading :-)
	}, [executeGetList, executeGetItems]);

	const navigate = useNavigate();
	const styles = useStyles();

	const columns: TableColumnDefinition<WishlistItem>[] = [
		createTableColumn<WishlistItem>({
			columnId: "name",
			compare: (a, b) => {
				return a.name.localeCompare(b.name);
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "priority",
			compare: (a, b) => {
				return b.priority - a.priority;
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "price",
			compare: (a, b) => {
				return a.price - b.price;
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "listedDate",
			compare: (a, b) => {
				// comparing date property works as the value is in the format YYYY-MM-DDTHH:mm:ss.sssZ :-)
				return (a.listedDate ?? "").localeCompare(b.listedDate ?? "");
			},
		}),
	];

	const adminOrOwner = wishlistApi.entity?.userAccessType === "owner" || wishlistApi.entity?.userAccessType === "admin";

	useEffect(() => {
		if (wishlistApi.entity && !adminOrOwner) {
			console.log("not admin or owner", wishlistApi.entity);
			navigate(`/lists/${wishlistId}`);
		}
	});
	function filterItems(items: WishlistItem[] | null) {
		return items?.filter((item) => item.archived);
	}

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: filterItems(items) ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "priority", sortDirection: "descending" },
			}),
		]
	);
	const headerSortProps = (columnId: TableColumnId) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const error = errorList || errorItems;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingListApi || isCallingItemsApi || loading) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	const rows = sort(getRows());

	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / Archived items
			</h1>
			<div className="wishlist-show-list">
				{rows?.length === 0 ? (
					<>No items in this wishlist.</>
				) : (
					<Table sortable>
						<TableHeader>
							<TableRow>
								<TableHeaderCell {...headerSortProps("name")}>Name</TableHeaderCell>
								<TableHeaderCell {...headerSortProps("priority")}>Priority</TableHeaderCell>
								<TableHeaderCell {...headerSortProps("price")}>Price</TableHeaderCell>
								<TableHeaderCell {...headerSortProps("listedDate")}>listedDate</TableHeaderCell>
								<TableHeaderCell></TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{rows?.map((row) => (
								<TableRow key={row.item.id}>
									<TableCell>
										<TableCellLayout appearance="primary">
											<Link to={`/lists/${wishlistId}/items/${row.item.id}`}>{row.item.name}</Link>
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<Priority value={row.item.priority} />
									</TableCell>
									<TableCell>{row.item.price ? `£${row.item.price}` : ""}</TableCell>
									<TableCell>{row.item.listedDate ? new Date(Date.parse(row.item.listedDate)).toDateString() : ""}</TableCell>
									<TableCell>
										<Link to={`/lists/${wishlistId}/items/${row.item.id}/edit`}>
											<Button icon={<Edit32Regular />}>Edit</Button>
										</Link>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</div>
			<div className="wishlist-show-list-small">
				{/* Rendering for narrower devices/windows goes here */}
				{rows?.map((row) => (
					<Link key={row.item.id} to={`/lists/${wishlistId}/items/${row.item.id}`} className="wishlist-show-item-small">
						<Card className={styles.card} orientation="vertical">
							<CardHeader
								header={
									<Text weight="semibold" size={400}>
										{row.item.name}
									</Text>
								}
								className={styles.cardHeader}
							></CardHeader>
							<CardFooter>
								<Priority value={row.item.priority} />
								<Text size={300} className="wishlist-show-item-small-price">
									&pound;{row.item.price}
								</Text>
								<Link to={`/lists/${wishlistId}/items/${row.item.id}/edit`} className="wishlist-show-item-small-buy">
									<Button icon={<Edit32Regular />}>Edit</Button>
								</Link>
							</CardFooter>
						</Card>
					</Link>
				))}
			</div>
		</>
	);
};

import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiPostWithAuth, useEntityApiSimple } from "../hooks/useEntityApi";
import { protectedResources } from "../authConfig";
import { Wishlist } from "../models/Wishlist";
import { WishlistItem } from "../models/WishlistItem";
import { Button } from "@fluentui/react-components";
import { useDialogStyles } from "../hooks/useDialogStyles";
import { Cart24Regular } from "@fluentui/react-icons";
import { UserContext } from "../components/RequireUser";
import { Link } from "react-router-dom";

export const WishlistItemReturn = () => {
	const { wishlistId, wishlistItemId } = useParams() as { wishlistId: string; wishlistItemId: string };
	const [activityMessage, setActivityMessage] = useState<string | null>("loading...");
	const [itemUnavailable, setItemUnavailable] = useState<boolean>(false);
	const user = useContext(UserContext);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}`,
	});
	const returnItemApi = useApiPostWithAuth<void, WishlistItem>(
		protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}/return`
	);

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeGet: executeGetItem, isCallingApi: isCallingItemsApi, error: errorItems, entity: item } = wishlistItemsApi;

	const navigate = useNavigate();

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItem().then((result) => {
				const { entity: item } = result;
				if (!item) {
					setItemUnavailable(true);
				} else if (item.bought && item.bought.buyerId != user.userId) {
					setItemUnavailable(true);
				}
			});
		}
		load();
		setActivityMessage(null); // once load is called the isCallingApiXXX variables track the loading :-)
	}, [executeGetList, executeGetItem]);

	const returnItem = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		setActivityMessage("returning...");

		try {
			await returnItemApi.execute();
		} catch (error) {
			console.log(error);
		}
		// refresh the item
		console.log("Refreshing item...");
		const item = await executeGetItem();
		console.log(item);
	};
	const styles = useDialogStyles();

	if (isCallingListApi || isCallingItemsApi || returnItemApi.isCallingApi) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}
	const error = errorList || errorItems || returnItemApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	if (itemUnavailable) {
		return (
			<>
				<h1 className="heading">
					<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name ?? "unknown"} / buy
				</h1>
				<p>Sorry, you don't seem to have bought that item!</p>
				<p>
					<Link to={`/lists/${wishlistId}`}>Back to list</Link>
				</p>
			</>
		);
	}

	if (wishlist && item && !item.bought) {
		return (
			<>
				<h1 className="heading">
					<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name} / return
				</h1>
				<p>{item.name} has been returned to the list...</p>
			</>
		);
	}

	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name} / return
			</h1>
			{activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
			<p>
				You previously said that you were going to get <span className="item-name">{item?.name}</span>.
			</p>
			<p>If you are no longer getting this then click on the "Return item to list" button below.</p>
			<div className={styles.actions}>
				<Button appearance="primary" icon={<Cart24Regular />} onClick={returnItem}>
					Return item to list
				</Button>
				<Button appearance="secondary" className={styles.buttonGap} onClick={() => navigate(`/lists/${wishlistItemId}`)}>
					Cancel
				</Button>
			</div>
		</>
	);
};

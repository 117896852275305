import { NavigationBar } from "./NavigationBar";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const PageLayout = (props: { children: ReactNode }) => {
	/**
	 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
	 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
	 * only render their children if a user is authenticated or unauthenticated, respectively.
	 */
	return (
		<>
			<NavigationBar />
			<div className="container body-content">
				<div>{props.children}</div>
			</div>
			<footer>
				&copy; 2023 - <Link to="https://wishlists.leeks.net">wishlists.leeks.net</Link>
			</footer>
		</>
	);
};

import { useCallback, useEffect, useState } from "react";
import { useBoolean } from "../hooks/useBoolean";
import {
	Button,
	Dialog,
	DialogSurface,
	DialogBody,
	DialogTitle,
	DialogContent,
	DialogActions,
	TableColumnDefinition,
	createTableColumn,
	useTableFeatures,
	useTableSort,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "@fluentui/react-components";
import { protectedResources } from "../authConfig";
import { useEntityApiSimple } from "../hooks/useEntityApi";
import { Invite } from "../models/Invite";
import { Delete32Regular } from "@fluentui/react-icons";

import { useDialogStyles } from "../hooks/useDialogStyles";

export const InviteList = (params: { wishlistId: string }) => {
	const { wishlistId } = params;
	const [activityMessage, setActivityMessage] = useState<string | null>(null);

	const [isDeleteConfirmationDialogOpen, { setTrue: showDeleteConfirmationDialog, setFalse: hideDeleteConfirmationDialog }] =
		useBoolean(false);
	const [inviteToDelete, setInviteToDelete] = useState<Invite | null>(null);

	const invitesApi = useEntityApiSimple<Invite>({
		collectionEndpoint: protectedResources.api.endpoint + `api/invites`,
	});

	const { executeList, entityList: items } = invitesApi; // extract this as dependency for useEffect below (since this is what is )

	useEffect(() => {
		async function execute() {
			setActivityMessage("loading...");
			await executeList(`wishlistId=${wishlistId}`);
			setActivityMessage(null);
		}
		execute();
	}, [executeList]);

	const confirmDelete = useCallback(
		function (invite: Invite) {
			setInviteToDelete(invite);
			showDeleteConfirmationDialog();
		},
		[setInviteToDelete, showDeleteConfirmationDialog]
	);
	const deleteInvite = useCallback(
		async function () {
			if (!inviteToDelete) {
				console.log("!!!deleteInvite: no inviteToDelete");
				setInviteToDelete(null);
				hideDeleteConfirmationDialog();
				return;
			}
			setActivityMessage("deleting...");
			await invitesApi.executeDeleteItem(inviteToDelete.id.toString());
			await executeList(`wishlistId=${wishlistId}`);
			setActivityMessage(null);
			setInviteToDelete(null);
			hideDeleteConfirmationDialog();
		},
		[inviteToDelete]
	);

	const columns: TableColumnDefinition<Invite>[] = [
		createTableColumn<Invite>({
			columnId: "id",
			compare: (a, b) => {
				return b.id - a.id;
			},
		}),
		createTableColumn<Invite>({
			columnId: "recipientEmail",
			compare: (a, b) => {
				return a.recipientEmail.localeCompare(b.recipientEmail);
			},
		}),
		createTableColumn<Invite>({
			columnId: "createdDate",
			compare: (a, b) => {
				// comparing date property works as the value is in the format YYYY-MM-DDTHH:mm:ss.sssZ :-)
				return a.createdDate.localeCompare(b.createdDate);
			},
		}),
		createTableColumn<Invite>({
			columnId: "status",
			compare: (a, b) => {
				return a.status.localeCompare(b.status);
			},
		}),
	];

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: items ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "created", sortDirection: "descending" },
			}),
		]
	);
	const headerSortProps = (columnId: keyof Invite) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const rows = sort(getRows());

	const styles = useDialogStyles();

	return (
		<>
			{activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
			<div className="invite-list">
				{rows?.length === 0 ? (
					<>No invites for this wishlist.</>
				) : (
					<>
						<Table sortable>
							<TableHeader>
								<TableRow>
									<TableHeaderCell {...headerSortProps("id")}>Id</TableHeaderCell>
									<TableHeaderCell {...headerSortProps("recipientEmail")}>Email</TableHeaderCell>
									<TableHeaderCell {...headerSortProps("createdDate")}>Created</TableHeaderCell>
									<TableHeaderCell {...headerSortProps("status")}>Status</TableHeaderCell>
									<TableHeaderCell></TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{rows?.map((row) => (
									<TableRow key={row.item.id}>
										<TableCell>{row.item.id}</TableCell>
										<TableCell>{row.item.recipientEmail}</TableCell>
										<TableCell>{row.item.createdDate ? new Date(Date.parse(row.item.createdDate)).toDateString() : ""}</TableCell>
										<TableCell>{row.item.status}</TableCell>
										<TableCell>
											<Button icon={<Delete32Regular />} onClick={() => confirmDelete(row.item)}>
												Delete
											</Button>

											{/* <Link to={`/lists/${wishlistId}/items/${row.item.id}/return`}>
										<Button icon={<ArrowBounce16Regular />}>Return this...</Button>
									</Link> */}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<Dialog open={isDeleteConfirmationDialogOpen}>
							<DialogSurface>
								<DialogBody>
									<DialogTitle>Delete invite?</DialogTitle>
									<DialogContent>Are you sure you want to delete the selected invite ({inviteToDelete?.id})?</DialogContent>
									<DialogActions>
										<Button appearance="secondary" onClick={hideDeleteConfirmationDialog}>
											Cancel
										</Button>
										<Button appearance="primary" onClick={deleteInvite}>
											Delete
										</Button>
									</DialogActions>
								</DialogBody>
							</DialogSurface>
						</Dialog>
					</>
				)}
			</div>
		</>
	);
};

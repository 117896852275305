import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import { Button, Navbar } from 'react-bootstrap';
import { loginRequest } from "../authConfig";
import { Link } from "react-router-dom";

import { ToggleButton, Button } from "@fluentui/react-components";
import "./NavigationBar.css";
import { LineHorizontal416Filled } from "@fluentui/react-icons";
import { useState } from "react";

const SignInSignOutButton = () => {
	const { instance } = useMsal();
	// const activeAccount = instance ? instance.getActiveAccount() : null;
	return (
		<>
			<AuthenticatedTemplate>
				{/* <span>{activeAccount?.name}</span> */}
				<Button appearance="secondary" onClick={() => instance.logout()} className="nav-right">
					Sign Out
				</Button>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Button appearance="secondary" onClick={() => instance.loginRedirect(loginRequest)} className="nav-right">
					Sign In
				</Button>
			</UnauthenticatedTemplate>
		</>
	);
};

export const NavigationBar = () => {
	const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
	const toggleMenuExpanded = () => {
		setMenuExpanded(!menuExpanded);
	};
	return (
		<>
			<div className={`topnav ${menuExpanded ? "responsive" : ""}`} id="myTopnav">
				<Link to="/" className="active">
					wishlists.leeks.net
				</Link>
				<SignInSignOutButton />
				<ToggleButton
					className="icon"
					icon={<LineHorizontal416Filled />}
					iconPosition="before"
					onClick={() => toggleMenuExpanded()}
					checked={menuExpanded}
				></ToggleButton>
			</div>
		</>
	);
};

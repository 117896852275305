import { useNavigate, useParams } from "react-router-dom";
import { protectedResources } from "../authConfig";
import { useApiGetWithAuth, useApiPostWithAuth, useEntityApi } from "../hooks/useEntityApi";
import { User, UserEditModel } from "../models/User";
import { useCallback, useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "@fluentui/react-components";
import { IPublicClientApplication } from "@azure/msal-browser";

function getAccount(msal: IPublicClientApplication) {
	const currentAccounts = msal.getAllAccounts();
	console.log({ currentAccounts });
	let accountId = "";
	if (currentAccounts.length === 0) {
		return null;
	} else if (currentAccounts.length > 1) {
		// TODO Add account choosing logic here if we encounter this state!
		console.warn("Multiple accounts detected.");
		return null;
	} else if (currentAccounts.length === 1) {
		accountId = currentAccounts[0].homeAccountId;
	}
	return msal.getAccountByHomeId(accountId);
}
const WishlistInviteAcceptAuthenticated = (props: { inviteCode: string }) => {
	const [progressMessage, setProgressMessage] = useState("Loading...");
	const [showAcceptButton, setShowAcceptButton] = useState(false); // show accept button after user is loaded
	const [createUser, setCreateUser] = useState(false); // does the user need to be created?
	const [done, setDone] = useState(false); // indicates complete, not success
	const [displayName, setDisplayName] = useState<string>("");

	const { inviteCode } = props;
	const userApi = useEntityApi<User, UserEditModel>({
		collectionEndpoint: protectedResources.api.endpoint + "api/me",
		individualEndpoint: protectedResources.api.endpoint + "api/me",
		convertToEdit: (entity) => {
			return { displayName: entity.displayName };
		},
		initialEntityValue: { displayName: "", userId: "", userType: "user", email: "123" },
	});
	const { executeGet: executeUserGet, executeUpdate: executeUserUpdate } = userApi;
	const {
		execute: executeInviteGet,
		result: wishlist,
		error: errorInviteGet,
	} = useApiGetWithAuth<{ name: string }>(protectedResources.api.endpoint + `api/invites/info?inviteCode=${inviteCode}`);
	const { execute: executeInviteAccept, error: errorInviteAccept } = useApiPostWithAuth<void, void>(
		protectedResources.api.endpoint + `api/invites/${inviteCode}/accept`
	);

	const { instance: msal } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();

	useEffect(() => {
		if (!inviteCode) {
			navigate("/");
		}
	});
	useEffect(() => {
		async function doIt() {
			if (isAuthenticated) {
				console.log("** User is authenticated - getting user details");
				const { entity: user } = await executeUserGet();

				if (user) {
					console.log("*** User found");
					setCreateUser(false);
				} else {
					console.log("*** User not found");
					setCreateUser(true);

					const account = getAccount(msal);
					if (!account) {
						throw new Error("No account found");
					}
					const accountName = account.name;
					if (!accountName) {
						throw new Error("Account name not set");
					}
					console.log("Display name:", accountName);
					setDisplayName(accountName);
				}

				const wishlist = await executeInviteGet();
				if (wishlist) {
					setShowAcceptButton(true);
				}
			}
		}
		doIt();
	}, []);
	const acceptInvite = useCallback(async () => {
		console.log("In acceptInvite - createUser:", createUser);
		userApi.entity = { displayName: displayName, userId: "", userType: "user", email: "" };
		console.log("userApi.entity:", userApi.entity);
		if (createUser) {
			setProgressMessage("Registering user...");
			console.log("userApi.entity:", userApi.entity);
			const updateResult = await executeUserUpdate(userApi.entity ?? undefined);
			const error = updateResult.error;
			if (error) {
				console.log("***Error registering user");
				setProgressMessage("Error registering user - " + error.message);
				return;
			}
		}
		console.log("*** Accepting invite");
		await setProgressMessage("Accepting invite...");

		// call accept invite endpoint
		await executeInviteAccept();
		setDone(true);
	}, [createUser, userApi]);

	useEffect(() => {
		if (done) {
			if (errorInviteAccept) {
				setProgressMessage("Error accepting invite - " + errorInviteAccept.message);
				return;
			}
			// Show success message and auto-redirect after short time
			setProgressMessage("Invite accepted!");
			setTimeout(() => navigate("/lists"), 1500);
		}
	});

	if (errorInviteAccept) {
		return <>Error: {errorInviteAccept.message}</>;
	}
	if (errorInviteGet) {
		return <>Error: {errorInviteGet.message}</>;
	}
	if (showAcceptButton && wishlist) {
		return (
			<>
				<p>To accept the invite to '{wishlist.name}', click the button below.</p>
				<Button appearance="primary" onClick={acceptInvite} className="nav-right">
					Accept Invite
				</Button>
			</>
		);
	}
	return <>{progressMessage ?? "..."}</>;
};

export const WishlistInviteAccept = () => {
	const { inviteCode } = useParams() as { inviteCode: string };
	const { instance: msal } = useMsal();

	return (
		<>
			<AuthenticatedTemplate>
				<WishlistInviteAcceptAuthenticated inviteCode={inviteCode} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>To accept the invitation, please sign in with your Microsoft account</p>
				<Button appearance="secondary" onClick={() => msal.loginRedirect(loginRequest)} className="nav-right">
					Sign In
				</Button>
			</UnauthenticatedTemplate>{" "}
		</>
	);
};

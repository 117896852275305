import { useState, useCallback } from "react";

export function useBoolean(
	initialValue: boolean
): [boolean, { setTrue: () => void; setFalse: () => void }] {
	const [value, setValue] = useState(initialValue);
	function setTrue() {
		setValue(true);
	}
	function setFalse() {
		setValue(false);
	}

	return [
		value,
		{
			setTrue: useCallback(setTrue, [setValue]),
			setFalse: useCallback(setFalse, [setValue]),
		},
	];
}
